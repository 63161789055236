import React from 'react'

export default function PackCards(props) {
    return (
        <div className={`bg-white shadow-xl w-fit font-poppins p-10 py-20 outline-none rounded-lg hover:outline-${props.color}-400 hover:scale-110 ease-in-out`}>
            <div className='flex flex-col justify-center items-center space-y-2 '>
                <div className="">
                    <h1 className={`text-2xl font-semibold text-${props.color}-400`}>{props.packageName} Package</h1>
                </div>
                <div className="self-center flex flex-col justify-between items-center space-x-3">
                    <h5 className={`text-${props.color}-600 text-2xl font-bold`}>{props.price}rs</h5>

                </div>
                <div className="self-start flex justify-between items-center space-x-3">
                    <h5 className='self-start'>You will get</h5>

                </div>
                <div className="self-start flex justify-between items-center space-x-3">
                    <img className='w-5 h-5' alt='' src="https://img.icons8.com/material-sharp/24/4a7d6d/checked--v1.png" />

                    <h3 className=''>{props.photosVideo}</h3>

                </div>
                <div className="self-start flex justify-between items-center space-x-3">
                    <img className='w-5 h-5' alt='' src="https://img.icons8.com/material-sharp/24/4a7d6d/checked--v1.png" />

                    <h3 className=''>50 Sheets Album</h3>

                </div>
                <div className="self-start flex justify-between items-center space-x-3">
                    <img className='w-5 h-5' alt='' src="https://img.icons8.com/material-sharp/24/4a7d6d/checked--v1.png" />

                    <h3 className=''>Photography & HD Videos For 2 days</h3>

                </div>
                <div className="self-start flex justify-between items-center space-x-3">
                    <img className='w-5 h-5' alt='' src="https://img.icons8.com/material-sharp/24/4a7d6d/checked--v1.png" />

                    <h3 className=''>{props.inlargementSize} 2 Inlargement</h3>

                </div>
                <div className={`self-start flex justify-between items-center space-x-3 ${props.droneWedding === ""?"hidden":""}`}>
                    <img className='w-5 h-5' alt='' src="https://img.icons8.com/material-sharp/24/4a7d6d/checked--v1.png" />

                    <h3 className=''>{props.droneWedding}</h3>

                </div>
                <div className={`self-start flex justify-between items-center space-x-3 ${props.candid === ""?"hidden":""}`}>
                    <img className='w-5 h-5' alt='' src="https://img.icons8.com/material-sharp/24/4a7d6d/checked--v1.png" />

                    <h3 className=''>{props.candid}</h3>

                </div>
                
                <div className='p-5'>

                    <p className='text-sm text-stone-400 self-center text-center'>Prices will vary outside Dehradun, Uttarakhand</p>
                    <p className='text-sm text-stone-400 self-center text-center'>For more contact</p>

                </div>

            </div>
        </div>
    )
}
