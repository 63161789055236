import React, { memo } from 'react'

function SocialBar() {
    return (
        <div className='fixed z-40 top-24 left-3'>
            <div className="icon-bar block space-y-1">
                <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/profile.php?id=100063664945445&mibextid=ZbWKwL" className="block rounded facebook bg-[#3B5998]  text-white px-3 py-1"><i className="fa fa-facebook"></i></a>
                <a target={"_blank"} rel="noreferrer" href="https://g.page/r/CV07hk81QGcJEBM " className="block rounded google bg-[#dd4b39] px-3 py-1 text-white"><i className="fa fa-google"></i></a>
                <a target={"_blank"} rel="noreferrer" href="https://instagram.com/gems_videography?igshid=NDk5N2NlZjQ=" className="block rounded instagram bg-[#8a3ab9] px-3 py-1 text-white"><i className="fa fa-instagram"></i></a>
                <a target={"_blank"} rel="noreferrer" href="whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com" className="block rounded whatsapp bg-[#075e54] px-3 py-1"><i className="fa fa-whatsapp text-white"></i></a>
            </div>
        </div>
    )
}

export default memo(SocialBar)