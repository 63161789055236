import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";
import SocialBar from "./Components/SocialBar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Gallery from "./Components/Gallery";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <>
    <Helmet>
      <title>Gems Studio</title>
      <meta name="description" content="Gems Studio is a professional photography and videography business based in Dehradun, india with over 30 years of experience in wedding photography & videography. Book your dream wedding photographer with us and capture 
      timeless memories that will last a lifetime. Our professional photographers specialize in capturing every 
      precious moment of your special day with all india coverage/reach"/>
      <meta name="keywords" content="Wedding photographer, studio,gems studio, marriage, photography, bridal, groom, engagement, photo session, professional, pictures, memories, special day, Cinematic photography, Photography gallery, images, booking, studio, professional, pictures, memories, photo session, engagement, bridal, portraits, wedding, family, event"/>
      <meta name="google-site-verification" content="SIZ7T-aYZC6z7Ce8cvK5QjCpaEoLIay_BtyLw3dGbe0" />
    </Helmet>
      <Router>
        <div>
          <Navbar />
          <SocialBar/>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/galleries' element={<Gallery />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </>
  )
}