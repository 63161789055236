import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className='z-40 relative bg-white'>
      <div className='grid md:grid-cols-2 xl:grid-cols-4 xl:space-x-5 gap-y-10 font-poppins p-16'>
        <div className='md:w-[55%] space-y-7 text-sm'>
          <h6 className='underline underline-offset-8 decoration-wavy decoration-green-800 '>About Us</h6>
          <p>We're Gems, a team of photographers & videographers based in Dehradun, specializing in capturing timeless and emotive moments of your special day.  Our team is made up of skilled and creative professionals who are passionate about what they do, and we are excited to be a part of your journey as you start your new life together.</p>
        </div>
        <div className='md:w-[55%] space-y-7'>
          <h6 className='underline underline-offset-8 decoration-wavy decoration-green-800 '>Contact Us</h6>
          <div className='flex items-center text-sm'>
            <img className='w-6 pr-2' src="https://img.icons8.com/material-sharp/24/4a7d6d/marker.png" alt='' />
            <p>108/1 Kanwali Road, Dehradun Uttrakhand</p>
          </div>
          <div className='flex items-center text-sm'>
            <img className='w-6 pr-2' src="https://img.icons8.com/ios-glyphs/30/4a7d6d/iphone14-pro.png" alt='' />
            <a href='tel:9897267872'>9897267872</a>

          </div>
          <div className='flex items-center text-sm'>
            <img className='w-6 pr-2' src="https://img.icons8.com/ios-glyphs/30/4a7d6d/whatsapp.png" alt='' />
            <a href='whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com' className='block'>9897267872</a>


          </div>
          <div className='flex items-center text-sm'>
            <img className='w-6 pr-2' src="https://img.icons8.com/material-rounded/24/4a7d6d/filled-message.png" alt='' />
            <a href='mailto:contactgemsstudio@gmail.com'>contactgemsstudio@gmail.com</a>


          </div>
        </div>
        <div className='md:w-[55%] text-sm'>
          <h6 className='underline underline-offset-8 decoration-wavy decoration-green-800 '>Quick Links</h6>
          <ul className='space-y-5 py-7'>
            <li><Link to='/' >Home</Link> </li>
            <li><Link to='/galleries' >Gallerie</Link> </li>
            <li><Link to='/contact' >Contact</Link> </li>

          </ul>
        </div>
        <div className='md:w-[55%] text-sm'>
          <h6 className='underline underline-offset-8 decoration-wavy decoration-green-800 '>Follow</h6>
          <ul className='space-y-5 py-7'>
            <li><a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/profile.php?id=100063664945445&mibextid=ZbWKwL">Facebook</a></li>
            <li><a target={"_blank"} rel="noreferrer" href="https://instagram.com/gems_videography?igshid=NDk5N2NlZjQ=">Instagram</a></li>
            <li><a target={"_blank"} rel="noreferrer" href="https://g.page/r/CV07hk81QGcJEBM ">Google</a></li>
          </ul>
        </div>
      </div>
      <div className='text-sm font-poppins text-center py-10 font-semibold'> <span>2023</span>
        <span>&#169; GemsStudio. Developed by <a href="//www.sauravpaintola.tk" target={'_blank'} rel="noopener noreferrer">Saurav Paintola</a> <a href="https://www.knowtorial.com" className='text-gray-400' target={'_blank'} rel="noopener noreferrer">(Knowtorial)</a> .</span>

        <div className=' right-10 absolute flex justify-center items-center rounded-full bg-[#4A7D6D] text-white w-8 h-8'>
          <img onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }} alt='' className=' scroll-smooth animate-bounce w-5 h-5' src="https://img.icons8.com/external-inkubators-detailed-outline-inkubators/25/FFFFFF/external-up-arrow-arrows-inkubators-detailed-outline-inkubators-2.png" />

        </div>
      </div>
    </footer>
  )
}
