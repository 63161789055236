import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import bg1 from "../Source/img/gems.png"

export default function Navbar() {
    const hiddenNav = useRef()
    const hiddenNavToggle = () => {
        if (hiddenNav.current.style.visibility === 'hidden') {
            hiddenNav.current.style.visibility = 'visible'

        }

        else {
            hiddenNav.current.style.visibility = 'hidden'


        }
    }
    return (
        <nav className='w-full absolute px-10 py-5 z-50 font-poppins'>
            <ul className='flex items-center justify-between'>
                <li className='flex space-x-30'>
                </li>
                <li className='flex flex-col justify-center items-center space-y-3'>
                    <div className='w-16 ml-20'> <img src={bg1} alt="" /></div>
                    <div>
                        <ul className='ml-20 hidden text-white xl:flex justify-center space-x-8'>
                            <li><Link to="/">Home</Link> </li>
                            <li><Link to="/galleries">Galleries</Link> </li>
                            <li><Link to="/contact">Contact</Link> </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className='hidden xl:flex bg-[#4A7D6D] text-white w-fit px-3 py-2 rounded-lg justify-center items-center space-x-2'> <img className='w-5 h-5' src="https://img.icons8.com/office/16/null/whatsapp--v1.png" alt='' /> <a href="whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com">Book Now</a> </div>
                    <div className='xl:hidden w-12 h-12 bg-white rounded-full flex justify-center items-center' onClick={hiddenNavToggle}>
                        <div className='grid grid-cols-3 gap-y-1 gap-x-1 content-center' >
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                            <div className="w-1 h-1 rounded-full bg-[#4A7D6D] hover:scale-150"></div>
                        </div>
                    </div>
                </li>
            </ul>


            <div ref={hiddenNav} className='invisible h-[100vh] flex flex-col w-full bg-black fixed top-0 left-0 z-40 px-10 py-5 space-y-5' id='phoneNav'>
                <p className='cursor-pointer  font-bold text-3xl transition-opacity text-white' onClick={hiddenNavToggle}  >&times;</p>
                <ul className=' text-white  justify-center text-xl font-bold space-y-4'>
                    <li onClick={() => {
                        hiddenNav.current.style.visibility = 'hidden'

                    }}><Link to="/">Home +</Link> </li>
                    <li onClick={() => {
                        hiddenNav.current.style.visibility = 'hidden'

                    }}><Link to="/galleries">Galleries +</Link> </li>
                    <li onClick={() => {
                        hiddenNav.current.style.visibility = 'hidden'

                    }}><Link to="/contact">Contact +</Link> </li>
                    <div className='hidden xl:flex bg-[#4A7D6D] text-white w-fit px-3 py-2 rounded-lg justify-center items-center space-x-2'> <img className='w-5 h-5' src="https://img.icons8.com/office/16/null/whatsapp--v1.png" alt='' /> <a href="whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com">Book Now</a> </div>
                </ul>
            </div>
        </nav>
    )
}