import React from 'react'
import homep1 from "../Source/img/homep1.jpg"
import homep2 from "../Source/img/homep2.jpg"
import homep3 from "../Source/img/homep3.jpg"
import homep4 from "../Source/img/homep4.jpg"
import PackCards from './PackCards'


export default function Home() {
    window.scrollTo({ top: 0, behavior: 'smooth' })



    return (
        <div className='relative'>
            <div id='homeSecOne' className=' w-full h-[100vh] absolute bg-gray-700 opacity-30 font-poppins'></div>
            <div className={`bg-homebg3  h-[100vh] bg-cover bg-center flex justify-center items-center`}>
                <div className='flex flex-col text-white text-center space-y-6 z-10 '><h1 className='text-5xl md:text-8xl font-poppins font-bold'>Wedding Photography</h1>
                    <p className='text-sm p-2 md:text-base'>We love photography and travel for meeting new beautiful people all over the world.</p>
                <div className='xl:hidden  self-center flex bg-[#4A7D6D] text-white w-fit px-3 py-2 rounded-lg justify-center items-center space-x-2'> <img className='w-5 h-5' src="https://img.icons8.com/office/16/null/whatsapp--v1.png" alt='' /> <a href="whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com">Book Now</a> </div>
                    </div>


            </div>
            <div className='py-40 flex flex-col xl:flex-row justify-between items-center'>
                <div className='xl:w-[50%] flex p-5 xl:p-0 flex-col md:flex-row justify-center items-center md:space-x-8'>
                    <div className='space-y-4 flex flex-col justify-center items-center'>
                        <div className='w-full  md:w-96 md:h-96 xl:w-72 xl:h-72  rounded-lg overflow-hidden'><img src={homep2} alt="" /></div>
                        <div className='w-full  md:w-96 md:h-96 xl:w-72 xl:h-72 rounded-lg overflow-hidden'><img src={homep3} alt="" /></div>

                    </div>
                    <div className='space-y-4 mt-10 flex flex-col justify-center items-center'>
                        <div className='w-full  md:w-96 md:h-96 xl:w-72 xl:h-72  rounded-lg overflow-hidden'><img src={homep4} alt="" /></div>
                        <div className='w-full  md:w-96 md:h-96 xl:w-72 xl:h-72  rounded-lg overflow-hidden'><img src={homep1} alt="" /></div>
                    </div>
                </div>
                <div className='xl:w-[50%] font-poppins px-10 py-24 space-y-8'>
                    <h5 className='text-green-800 font-semibold underline-offset-8 decoration-wavy decoration-green-800 underline'>Gems Studio</h5>
                    <h1 className='text-4xl  xl"text-5xl font-semibold '>We Love to Capture Beauty, Emotions and Magic Moments from Your Events</h1>
                    <p>We love photography and travel for meeting new beautiful people all over the world.and capturing the love, emotion, and special moments of their big day. Whether it's a traditional wedding in a grand ballroom or an intimate elopement in a secluded beach, we are always excited to document the unique love story of our clients. </p>

                    <p>Our team of experienced photographers and videographers is dedicated to creating timeless and emotive imagery that tells the tale of your love.</p>

                    <div className='flex bg-[#4A7D6D] text-white w-fit px-3 py-2 rounded-lg justify-center items-center space-x-2'> <img className='w-5 h-5' src="https://img.icons8.com/office/16/null/whatsapp--v1.png" alt='' /> <a href="whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com">Book Now</a> </div>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center space-y-10'>
                <h1 className='text-4xl  xl-text-5xl '>Our packages</h1>
                <div className='flex justify-center flex-col lg:flex-row lg:space-x-5'>
                    <PackCards packageName="Silver" candid="" droneWedding="" color="stone" price="45000" inlargementSize="16 x 24" photosVideo="Traditional Photos & Videos" />
                    <PackCards packageName="Gold" candid="Candid & Cinematic on wedding Day" droneWedding="Drone on wedding day" color="yellow" price="85000" inlargementSize=" 20 x 30" photosVideo="Traditional Photos & Videos" />

                </div>
                <div className='flex bg-[#4A7D6D] text-white w-fit px-3 py-2 rounded-lg justify-center items-center space-x-2'> <img className='w-5 h-5' src="https://img.icons8.com/office/16/null/whatsapp--v1.png" alt='' /> <a href="whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com">Book Now</a> </div>

            </div>

            <div className=' font-poppins p-16 space-y-10 xl:flex xl:space-x-5 justify-center items-center'>
                <div className='xl:w-[50%] space-y-5'>
                    <h6 className='underline underline-offset-8 decoration-wavy decoration-green-800 font-semibold text-green-800'>Get in Touch</h6>
                    <h3 className='font-bold text-2xl'>Book Us for Your Event</h3>
                    <p>And we promise to bring our passion, creativity, and expertise to capture the special moments of your event. Our team is dedicated to delivering high-quality imagery that tells the story of your celebration, and we take great care to ensure that every detail is captured beautifully. </p>
                </div>
                <div className='xl:w-[50%]'>
                    <form action="https://formspree.io/f/xzbqbozw" className='flex flex-col' method='post'>
                        <div className='xl:space-x-3 space-y-4 xl:space-y-0 text-black flex xl:flex-row flex-col'>
                            <input className='border border-gray-400 py-2 rounded-md w-full xl:w-40 pl-3 focus:outline-none' type="text" name="name" id="name" placeholder='Name' />
                            <input className='border border-gray-400 py-2 rounded-md w-full xl:w-40 pl-3 focus:outline-none' type="email" name="email" id="email" placeholder='Email' />
                            <input className='border border-gray-400 py-2 rounded-md w-full xl:w-40 pl-3 focus:outline-none' type="text" name="number" id="number" placeholder='Number' />

                        </div>
                        <textarea name="message" id="message" cols="25" rows="5" placeholder='Message' className='p-5  border border-gray-400 focus:outline-none w-full xl:w-[31rem] mt-8'></textarea>
                        <button type='submit' className='bg-[#4A7D6D] text-white w-fit px-3 py-2 rounded-md mt-6 font-bold text-sm'>Send Message</button>

                    </form>
                </div>
            </div>
        </div>
    )
}
