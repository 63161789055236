import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function Contact() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    return (
        <div className=' font-poppins'>
            <Helmet>
            <title>
                Contact Us
            </title>
            <meta name="description" content="Contact us for professional photography services. Whether you need a studio or on-location photographer, we are here to capture every special moment of your event. Contact us today to book a photo session, Gems Studio is a professional photography and videography business based in Dehradun, india with over 30 years of experience in wedding photography & videography."/>
            <meta name="keywords" content="Wedding photographer, studio, marriage, photography, bridal, groom, engagement, photo session, professional, pictures, memories,gems studio,special day, Cinematic photography, Photography gallery, images, booking, studio, professional, pictures, memories, photo session, engagement, bridal, portraits, wedding, family, event, Contact, mobile, phone_number, email"/>
            <meta name="google-site-verification" content="SIZ7T-aYZC6z7Ce8cvK5QjCpaEoLIay_BtyLw3dGbe0" />
            </Helmet>
            <div className='fixed bg-homebg2 scroll h-[100vh] w-full bg-cover bg-center -z-0'>
            </div>
            <div className='absolute bg-black z-0 opacity-40 h-[100vh] w-full'></div>
            <div className='h-[100vh] w-full text-white flex justify-center flex-col  items-center space-y-2 z-10 '>
                <h1 className='text-5xl md:text-8xl font-poppins font-bold z-20'>Contact</h1>
                <p className='text-sm md:text-base  z-20'>We're here to catch your event</p>
            </div>

            <div className='flex flex-col bg-white w-full z-30 relative xl:p-16  space-y-10  '>
                <div className="flex xl:flex-row flex-col">
                    <div className='xl:w-[50%] p-12'>
                        <h1 className='text-4xl md:text-5xl font-poppins font-extrabold h-20 z-20 '>We're Ready to Work with You. Get in Touch</h1>
                    </div>
                    <div className='xl:w-[50%] p-12 flex justify-between space-x-9'>
                        <div className='space-y-5 w-[50%]'>
                            <h5 className='flex justify-start underline underline-offset-8 decoration-wavy decoration-green-800 '>Main Office</h5>
                            <div>
                                <p>108/1 Kanwali Road, Dehradun Uttrakhand</p>
                                <a href='whatsapp://send?phone=9897267872&text=bookingFromGemsStudio.com' className='block'>9897267872</a>
                                <a href='mailto:contactgemsstudio@gmail.com'>contactgemsstudio@gmail.com</a>

                            </div>
                        </div>
                        <div className='flex flex-col justify-start items-start w-[40%]'>
                            <h5 className='underline underline-offset-8 decoration-wavy decoration-green-800 '>Social</h5>
                            <ul className='space-y-2 py-5'>
                                <li><Link to='/' >Home</Link> </li>
                                <li><Link to='/galleries' >Gallerie</Link> </li>
                                <li><Link to='/contact' >Contact</Link> </li>

                            </ul>

                        </div>
                    </div>
                </div>
                <div id='contactsecond' className="flex flex-col-reverse xl:flex-row w-full lg:space-x-20 items-center   lg:items-stretch justify-center">
                    <div className='lg:w-[50%] lg:p-10'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3444.0361444267774!2d78.01658542728956!3d30.321489723513146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390929f53fbcc5e5%3A0x2dab4d1a31ce29d7!2s108%2F1%2C%20Kanwali%20Rd%2C%20Laxman%20Chowk%2C%20Dehradun%2C%20Uttarakhand%20248001!5e0!3m2!1sen!2sin!4v1671456944591!5m2!1sen!2sin"  className='border-0 w-[100%] h-full' title='maps' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className='xl:w-[50%] flex '>
                        <form className=' w-full flex  flex-col justify-center items-center' action="https://formspree.io/f/xzbqbozw" method='post' >
                            <div className='w-full space-y-4 text-black flex  flex-col justify-center items-center'>
                                <input className='border border-gray-400 py-4 rounded-md w-[90%] pl-3 focus:outline-none' type="text" name="name" id="name" placeholder='Name' />
                                <input className='border border-gray-400 py-4 rounded-md w-[90%] pl-3 focus:outline-none' type="email" name="email" id="email" placeholder='Email' />
                                <input className='border border-gray-400 py-4 rounded-md w-[90%] pl-3 focus:outline-none' type="text" name="number" id="number" placeholder='Number' />

                            </div>
                            <textarea name="message" id="message"  placeholder='Message' className='p-5  border border-gray-400 focus:outline-none w-[90%] h-60 mt-8'></textarea>
                            <button type='submit' className='bg-[#4A7D6D] text-white w-fit px-4 py-3 rounded-md mt-6 font-bold text-sm'>Send Message</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
