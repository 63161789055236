import React, { useRef } from 'react'
import { Helmet } from 'react-helmet';
import gallery_1_1 from '../Source/img/gallery_1_1.jpg';
import gallery_1_2 from '../Source/img/gallery_1_2.jpg';
import gallery_1_3 from '../Source/img/gallery_1_3.jpg';
import gallery_1_4 from '../Source/img/gallery_1_4.jpg';
import gallery_1_5 from '../Source/img/gallery_1_5.jpg';
export default function Gallery() {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    let modelWindow = useRef()
    let modelImg = useRef()
    const modelImgSetter = async (e) => {

         switch (e.target.id) {
            case '1':
                modelImg.current.src = gallery_1_1
                break;
            case '2':
                modelImg.current.src = gallery_1_2
                break;
            case '3':
                modelImg.current.src = gallery_1_3
                break;
            case '4':
                modelImg.current.src = gallery_1_4
                break;
            case '5':
                modelImg.current.src = gallery_1_5
                break;

            default:
                break;
        }


        modelWindow.current.style.visibility = 'visible'
    }


    return (
        <div className='font-poppins relative '>
            <Helmet>
              <title>Gallery</title>
              <meta name='description' content="View our stunning collection of wedding 
              photo galleries showcasing the most beautiful moments and memories of our 
              clients' special day. Captured by professional photographers, each image 
              tells the unique love story of the couple. Gems Studio is a professional photography and videography business based in Dehradun, india with over 30 years of experience in wedding photography & videography."/>
              <meta name="keywords" content="Wedding photographer,gems studio,studio, marriage, photography, bridal, groom, engagement, photo session, professional, pictures, memories, special day, Cinematic photography, Photography gallery, images, booking, studio, professional, pictures, memories, photo session, engagement, bridal, portraits, wedding, family, event"/>
              <meta name="google-site-verification" content="SIZ7T-aYZC6z7Ce8cvK5QjCpaEoLIay_BtyLw3dGbe0" />
            </Helmet>
            <div ref={modelWindow} className='w-full h-[100vh] text-white fixed invisible z-50 flex justify-center items-center bg-[rgba(0,0,0,0.9)]' id='modelWindow'>
                <img ref={modelImg} src={gallery_1_1} alt="" className='w-auto h-auto' />
                <span className='cursor-pointer absolute right-20 top-10 font-bold text-3xl transition-opacity' onClick={() => { modelWindow.current.style.visibility = 'hidden' }}>&times;</span>
            </div>
            <div className='fixed bg-galleryBg scroll h-[100vh] w-full bg-cover bg-center -z-0'>
            </div>
            <div className='absolute bg-black z-0 opacity-40 h-[100vh] w-full'></div>
            <div className='h-[100vh] w-full text-white flex justify-center flex-col  items-center space-y-2 z-10 '>
                <h1 className='text-5xl md:text-8xl font-poppins font-bold z-20'>Gallery</h1>
                <p className='text-sm md:text-base  z-20'>Have a look at our work.</p>
            </div>
            <div className='flex flex-col bg-white w-full z-30 relative xl:p-16  space-y-10  '>
                <p>And you will see that we are passionate about capturing the special moments of your event. From intimate elopements to grand wedding celebrations, we have the skills and experience to document the unique love story of every couple.</p>

                <div className="container mx-auto">
                    <div className="grid-cols-3 p-20 space-y-2 lg:space-y-0 lg:grid lg:gap-3 lg:grid-rows-3">
                        <div className="w-full rounded">
                            <img src={gallery_1_1} id='1' onClick={modelImgSetter}
                                className="object-cover w-full h-full hover:ease-in-out"
                                alt="" />
                        </div>
                        <div className="w-full col-span-2 row-span-2 rounded">
                            <iframe className='w-full h-full' src="https://www.youtube.com/embed/Cr_f0IwpaUs?controls=0&amp;start=16&;autoplay=1&mute=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="w-full rounded">
                            <img src={gallery_1_2}
                                className="object-cover w-full h-full" onClick={modelImgSetter}
                                id='2'
                                alt="" />
                        </div>
                        <div className="w-full rounded">
                            <img src={gallery_1_3}
                                className="object-cover w-full h-full" onClick={modelImgSetter}
                                id='3'
                                alt="" />
                        </div>
                        <div className="w-full rounded">
                            <img src={gallery_1_4}
                                className="object-cover w-full h-full" onClick={modelImgSetter}
                                id='4'
                                alt="" />
                        </div>
                        <div className="w-full rounded">
                            <img src={gallery_1_5}
                                className="object-cover w-full h-full" onClick={modelImgSetter}
                                id='5'
                                alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
